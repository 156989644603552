import {computed, observable, makeObservable} from 'mobx';

import events from 'events.js';

import Store from 'core/stores/Store.js';
import {sessionStorageGetItem, sessionStorageSetItem} from 'core/utils.js';
import {
    TRUE_STR,
    BP,
    BP_FHIR,
    MD,
    REFERRALS,
    SITE,
    HCP,
    ZM,
} from 'core/constants.js';

export default class ParamStore extends Store {
    constructor(rootStore) {
        super();
        this.rootStore = rootStore;
        makeObservable(this, {
            client: observable,
            isClient: observable,
            isHfWidget: observable,
            isReferrals: observable,
            isStaff: observable,
            skin: observable,
            userType: observable,
            mh1BookingsUrl: observable,
            mh1AvailabilityUrl: observable,

            hasSkin: computed,
            showParam: computed,
            isProUser: computed,
        });

        this.CLIENTS = [BP, BP_FHIR, MD, ZM];

        this.setCameFromReferrals();
        this.setSessionVariables();
    }

    client = '';
    isClient = false;

    skin = '';

    isHfWidget = false;
    isReferrals = false;
    isStaff = false;
    userType = '';
    mh1BookingsUrl = '';
    mh1AvailabilityUrl = '';

    async setCameFromReferrals() {
        await events.ready();
        if (sessionStorageGetItem('cameFromReferrals') === null) {
            if (
                location.pathname === '/referrals/' ||
                location.pathname === '/redesign/referrals/'
            ) {
                sessionStorageSetItem('cameFromReferrals', true);
            } else {
                sessionStorageSetItem('cameFromReferrals', false);
            }
        }
    }

    async setSessionVariables() {
        await events.ready();
        const {
            client,
            integratedClient,
            isHfWidget,
            isReferrals,
            isStaff,
            skin,
            userType,
            mh1BookingsUrl,
            mh1AvailabilityUrl,
        } = document.body.dataset;

        const storeParams = {
            isClient: integratedClient === TRUE_STR,
            isReferrals: isReferrals === TRUE_STR,
            isStaff: isStaff === TRUE_STR,
            isHfWidget: isHfWidget === TRUE_STR,
            mh1BookingsUrl: mh1BookingsUrl,
            mh1AvailabilityUrl: mh1AvailabilityUrl,
        };
        if (this.CLIENTS.includes(client)) {
            storeParams['client'] = client;
        }
        if (skin) {
            storeParams['skin'] = skin;
        }
        if (userType) {
            storeParams['userType'] = userType;
        }

        this.updateStore({...storeParams});
    }

    get dataSource() {
        const selectedHF = this.rootStore?.healthFundStore?.healthFund;
        let source = 'healthshare';

        if (this.isHfWidget) {
            source = `heathshare-fap-${selectedHF?.name}`;
        } else if (selectedHF?.id) {
            source = `healthshare-filter-${selectedHF?.name}`;
        }

        return {
            source,
        };
    }

    get hasSkin() {
        return this.skin && !this.isClient;
    }

    get isProUser() {
        return this.userType && this.userType === HCP;
    }

    get showParam() {
        const inReferralsPage = window.location.pathname.includes(
            '/referrals/',
        );
        // To allow a referrals user to navigate the normal flow of the website
        // and see non-referrals specialties
        // Session storage variable are strings even when they're saved as bools
        const fromReferrals =
            sessionStorageGetItem('cameFromReferrals') === 'true';
        const isReferralsUser = this.isReferrals;
        if (
            this.isClient ||
            inReferralsPage ||
            (fromReferrals && isReferralsUser)
        ) {
            return REFERRALS;
        }
        return SITE;
    }
}
