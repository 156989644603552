import {computed, makeObservable, observable, reaction, when} from 'mobx';
import events from 'events.js';
import http from 'http.js';
import query from 'query.js';

import autobind from 'common/decorators/autobind.js';
import Store from 'core/stores/Store.js';
import {snakeToCamelObjectKeys} from 'utils/case_converter.js';

const defaultHFRs = [
    {
        id: null,
        displayName: 'All Types',
        name: 'All',
        order: 0,
        checked: true,
    },
    {
        id: 0,
        displayName: 'Out of Network',
        name: 'out of network',
        order: 0,
        tooltip: 'out of network',
        checked: false,
    },
];
export default class HealthFundStore extends Store {
    constructor(rootStore) {
        super();
        this.rootStore = rootStore;
        this._currentRequest = null;

        makeObservable(this, {
            healthFund: observable,
            healthFunds: observable,
            healthFundsLoading: observable,
            healthFundRelationships: observable,
            hasHFRLoaded: observable,
            gapSchemeId: observable,
            promotedGapSchemes: observable,

            gapSchemeInHealthFund: computed,
            healthFundThemeColor: computed,
        });

        this.saveQueryParams().then(() => {
            when(
                () => this.rootStore?.paramStore,
                () => this.fetchHealthFunds(),
            );
            reaction(
                () => this.healthFunds.length > 0,
                () => this.preloadHealthFundAvatars(),
            );
            reaction(
                () => this.healthFund,
                (healthFund) => {
                    if (healthFund?.id) {
                        query.addParams({'health_fund': healthFund.id});
                    } else {
                        query.removeParams(['health_fund', 'gap_scheme']);
                        this.updateStore({gapSchemeId: null});
                    }
                },
            );
        });
    }

    // null - no health fund selected
    // {} - "I don't have a health fund"
    healthFund = null;
    healthFunds = [];
    healthFundsLoading = false;

    hasHFRLoaded = false;
    healthFundRelationships = [];

    gapSchemeId = null;
    promotedGapSchemes = [];
    showBookings = false;

    async saveQueryParams() {
        await events.ready();
        let healthFund;
        const {gap_scheme: gapScheme, health_fund} = query.parse();
        const updateParams = {};
        if (health_fund) {
            healthFund = health_fund;
        } else {
            healthFund = document.body.dataset.healthFund;
        }
        if (healthFund) {
            updateParams['gapSchemeId'] = gapScheme
                ? parseInt(gapScheme)
                : null;
            updateParams['healthFund'] = {id: parseInt(healthFund)};
        } else {
            updateParams['gapSchemeId'] = null;
            updateParams['healthFund'] = null;
        }
        this.updateStore({...updateParams});
    }

    @autobind
    getCurrentURLPathList() {
        const url = new URL(window.location.href).pathname;
        const path = url.replace(/^\/|\/$/g, '').split('/');
        if (path.length === 1 && path[0] === '') {
            return [];
        }
        return path;
    }

    @autobind
    async selectHealthFund(healthFundId) {
        healthFundId = parseInt(healthFundId);
        await http.post({
            url: '/api/base/v1/health-fund-select/',
            data: {'health_fund': healthFundId},
        });
        if (healthFundId === 0) {
            this.updateStore({healthFund: {id: healthFundId}});
            return;
        }
        const selectedHealthFund = this.findHealthFund(healthFundId);
        this.updateStore({healthFund: selectedHealthFund});
    }

    @autobind
    findHealthFund(healthFundId) {
        return (
            this.healthFunds?.find(
                (hf) => parseInt(hf.id) === parseInt(healthFundId),
            ) || null
        );
    }

    @autobind
    async fetchHealthFunds() {
        const url = '/api/base/v1/health-funds/';
        const data = {};
        if (this._currentRequest) {
            this._currentRequest.abort();
        }
        const {paramStore} = this.rootStore;
        if (paramStore?.showParam) {
            data.show = paramStore?.showParam;
        }
        this.updateStore({healthFundsLoading: true});
        const request = http.get({url, data});
        this._currentRequest = request;
        const response = await this._currentRequest;

        if (response?.length) {
            const healthFunds = response.map((d) => snakeToCamelObjectKeys(d));
            this.updateStore({
                healthFunds,
            });
            this._currentRequest = null;
            const selectedHealthFund = this.findHealthFund(
                this.healthFund?.id,
            );
            this.updateStore({
                healthFunds,
                healthFund: selectedHealthFund,
                healthFundsLoading: false,
            });
        }
    }

    @autobind
    preloadHealthFundAvatars() {
        this.healthFunds?.map((fund) => {
            if (fund.avatar) {
                const img = new Image();
                img.src = fund.avatar;
            }
        });
    }

    get gapSchemeInHealthFund() {
        return !!this.promotedGapSchemes?.find(
            (pgs) => pgs.healthFundId === this.healthFund?.id,
        );
    }

    get healthFundThemeColor() {
        return this.healthFund?.colorBrand
            ? {color: `#${this.healthFund.colorBrand}`}
            : null;
    }

    get isHealthFundBookingsEnabled() {
        const {isReferrals, isClient, skin} = this.rootStore.paramStore;
        const defaultBookingsCheck = !isReferrals && !isClient;

        if (this.healthFund && skin) {
            return this.healthFund.showBookings;
        }
        return defaultBookingsCheck;
    }

    @autobind
    enabledMH1BookingIntegration(practicePosition) {
        if (
            !this.isHealthFundBookingsEnabled ||
            !practicePosition ||
            !practicePosition.bookingIntegration
        ) {
            return null;
        }
        // Updates MH1 url based on MH1_URLS admin feature flag
        practicePosition.bookingIntegration[
            'url'
        ] = this.rootStore.paramStore.mh1BookingsUrl;

        return practicePosition.bookingIntegration;
    }

    @autobind
    async fetchRelationships(healthFundId) {
        if (!healthFundId) {
            return;
        }
        let result;
        try {
            result = await http.get({
                url: `/api/health-funds/v1/health-fund/${healthFundId}/relationships/`,
            });
        } catch (e) {
            this.rethrowError(`Fetching HFR results URL failed with :${e}`);
        } finally {
            if (result?.results?.length === 0) {
                this.updateStore({
                    healthFundRelationships: [],
                    hasHFRLoaded: true,
                });
                return;
            }
            const newResults = result?.results?.map((itm) => {
                return {...snakeToCamelObjectKeys(itm), checked: false};
            });
            if (this.healthFund.outOfNetworkDisplayName) {
                defaultHFRs[1].displayName = this.healthFund.outOfNetworkDisplayName;
            }
            const hfr = [...defaultHFRs];
            if (newResults) {
                hfr.splice(1, 0, ...newResults);
            }
            this.updateStore({
                healthFundRelationships: hfr,
                hasHFRLoaded: true,
            });
        }
    }
}
